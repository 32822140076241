<template>
  <div
    class="bg-white pt-space-md pt-md-space-lg pt-lg-space-xxl pb-space-md pb-lg-space-xxl px-lg-space-xxxl"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- title -->
          <h2
            class="h2-medium pb-space-md pb-lg-space-lg px-space-xs px-md-0 mb-0"
          >
            {{ title }}
          </h2>
          <!-- select future student type  -->
          <div
            class="d-flex flex-inline fw-bold pb-space-xs pb-lg-space-sm ps-space-xs px-md-0"
          >
            <span class="pt-space-xxs h2-small">I am a future</span>
            <div class="dropdown" @click="toggle">
              <p
                :value="selectedType"
                class="dropdownList bg-grey fw-bold position-relative w-100 h2-small pt-space-xxs ms-space-xxs mb-space-xxs border-bottom border-secondary border-4"
              >
                {{ selectedType }} student
                <!-- icon -->
                <font-awesome-icon
                  :icon="currentIcon"
                  role="presentation"
                ></font-awesome-icon>
              </p>
              <!-- student types -->
              <ul
                class="position-absolute ms-space-xxs w-100 py-space-xs px-0 border border-light-3 bg-white"
                v-show="isActive"
              >
                <li
                  v-for="(option, index) in $page?.undergradAdmissionRequirement
                    ?.admissionTypes"
                  :change="toggle"
                  class="py-space-xxs px-space-sm text-start"
                  :class="{ active: index == activeIndex }"
                  @click="updateselectedRequirementIndex(option!, index)"
                >
                  {{ option!.title }} student
                </li>
              </ul>
            </div>
          </div>

          <!-- description paragraph -->
          <div class="pb-space-md pb-lg-space-lg px-space-xs px-md-0">
            <div class="mb-0" v-html="returnDescription"></div>
          </div>

          <!-- collapse items -->
          <div class="row">
            <div class="col" v-if="currentRequirements">
              <div
                v-for="(req, index) in currentRequirements"
                :key="index"
                class="pb-space-xs"
              >
                <collapse-item
                  class="bg-white"
                  :collapse-id="`req-${req!.id}`"
                  border-variant="light-2"
                  :highlight-width="8"
                  :title="req!.title!"
                  :display-number="false"
                  @collapseShown="
                    analyticsComposable.trackCollapseEvent(
                      'onclick',
                      'open',
                      'click',
                      'main content',
                      title.toLowerCase(),
                      req!.title!.toLowerCase(),
                      'admission requirements'
                    )
                  "
                  @collapseHidden="
                    analyticsComposable.trackCollapseEvent(
                      'onclick',
                      'close',
                      'click',
                      'main content',
                      title.toLowerCase(),
                      req!.title!.toLowerCase(),
                      'admission requirements'
                    )
                  "
                  ><div v-html="req!.description"></div
                ></collapse-item>
              </div>
            </div>
          </div>

          <!-- show more/less links -->
          <div v-if="displayShowAllLess" class="row">
            <div class="col">
              <div class="text-center">
                <button
                  @click="handleShowAllLessClick"
                  class="btn btn-link fs-medium text-primary show-text text-decoration-underline"
                >
                  {{ getShowAllLessText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { CollapseItem } from "@rds-vue-ui/collapse-item";
import {
  ParagraphUndergradAdmissionRequirement,
  UndergradAdmissionRequirement,
} from "~/server/resolvers/resolvers-types";

interface Props {
  title: string;
  initialItemsNumber?: number;
}

const props = withDefaults(defineProps<Props>(), {
  initialItemsNumber: 5,
});

interface UndergradAdmissionReqData {
  undergradAdmissionRequirement: UndergradAdmissionRequirement;
}

const $page = await usePageQuery<UndergradAdmissionReqData>(
  `#graphql
  query {
    undergradAdmissionRequirement(id: "34b9c839-4180-4261-aab9-7e9f7ee2f836") {
      id
      title
      admissionTypes {
        id
        title
        description
        requirementItems {
          id
          title
          description
        }
      } 
    }
  }`,
  {},
  { key: "undergrad-admission-requirements" }
);

const selectedRequirementIndex = ref(0);
const selectedType = ref("first-year");
// replace with page querry
const isActive = ref(false);
const activeIndex = ref<number | null>(null);
const showAllItems = ref(false);

const currentIcon = computed(() => {
  return isActive.value ? "chevron-up" : "chevron-down";
});

const getShowAllLessText = computed(() => {
  return showAllItems.value ? "Show less" : "Show more";
});

const displayShowAllLess = computed(() => {
  if (
    $page?.undergradAdmissionRequirement?.admissionTypes &&
    $page?.undergradAdmissionRequirement?.admissionTypes[
      selectedRequirementIndex.value
    ] &&
    $page?.undergradAdmissionRequirement?.admissionTypes[
      selectedRequirementIndex.value
    ]?.requirementItems
  )
    return (
      $page?.undergradAdmissionRequirement.admissionTypes[
        selectedRequirementIndex.value
      ]!.requirementItems!.length > props.initialItemsNumber
    );
});

const returnDescription = computed(() => {
  if (
    $page?.undergradAdmissionRequirement?.admissionTypes &&
    $page?.undergradAdmissionRequirement?.admissionTypes[
      selectedRequirementIndex.value
    ] &&
    $page?.undergradAdmissionRequirement?.admissionTypes[
      selectedRequirementIndex.value
    ]?.description
  ) {
    return $page?.undergradAdmissionRequirement &&
      $page?.undergradAdmissionRequirement.admissionTypes[
        selectedRequirementIndex.value
      ] &&
      $page?.undergradAdmissionRequirement.admissionTypes[
        selectedRequirementIndex.value
      ]!.description
      ? $page?.undergradAdmissionRequirement.admissionTypes[
          selectedRequirementIndex.value
        ]!.description
      : "";
  } else {
    return "";
  }
});

const currentRequirements = computed(() => {
  if (
    $page?.undergradAdmissionRequirement?.admissionTypes &&
    $page?.undergradAdmissionRequirement?.admissionTypes[
      selectedRequirementIndex.value
    ] &&
    $page?.undergradAdmissionRequirement?.admissionTypes[
      selectedRequirementIndex.value
    ]?.requirementItems
  ) {
    return showAllItems.value
      ? $page?.undergradAdmissionRequirement.admissionTypes[
          selectedRequirementIndex.value
        ]!.requirementItems
      : $page?.undergradAdmissionRequirement.admissionTypes[
          selectedRequirementIndex.value
        ]!.requirementItems!.slice(0, props.initialItemsNumber);
  } else {
    return false;
  }
});

const handleShowAllLessClick = (event: Event) => {
  event.preventDefault();

  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    getShowAllLessText.value.toLowerCase(),
    "admission requirements"
  );

  showAllItems.value = !showAllItems.value;
};

const toggle = () => {
  isActive.value = !isActive.value;
};

const updateselectedRequirementIndex = (
  optionValue: ParagraphUndergradAdmissionRequirement,
  index: number
) => {
  if (optionValue.title) {
    selectedType.value = optionValue.title;
    activeIndex.value = index;
    selectedRequirementIndex.value = index;
    showAllItems.value = false;

    // analytics select event
    analyticsComposable.trackSelectEvent(
      "onclick",
      "click",
      "select student type",
      "main content",
      props.title.toLowerCase(),
      `${optionValue.title.toLowerCase()} student`,
      "admission requirement"
    );
  } else {
    console.error("option is missing a title");
  }
  return selectedRequirementIndex && activeIndex;
};
</script>

<style scoped>
.dropdownList {
  list-style-type: none;
  cursor: pointer;
}
ul {
  z-index: 99;
  list-style: none;
}
.active {
  background-color: var(--rds-light-2);
}
li:hover {
  cursor: pointer;
}
</style>
