<template>
  <section
    class="bg-white pt-0 pb-space-md pt-md-space-lg py-lg-space-xxl px-lg-space-xxxl"
  >
    <div class="container-lg">
      <!-- image -->
      <div class="row">
        <div
          class="col career-image rds-lazy"
          :data-background-image="imageSource"
        ></div>
        <div class="w-100 career-image-gradient"></div>
      </div>
      <!-- title row -->
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-10">
          <h2
            class="position-relative h2-medium title-margin pt-space-md pt-md-space-lg pt-lg-0 pb-space-sm pb-md-space-md pb-lg-space-sm px-0 px-md-space-xs px-lg-0 mb-0"
          >
            {{ title }}
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-10">
          <div
            class="fs-medium px-0 px-md-space-xs px-lg-0 pb-space-md pb-md-space-sm pb-lg-space-md mb-0"
            v-html="description"
          ></div>
        </div>
      </div>

      <!-- career cards -->
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-10">
          <div class="px-0 px-md-space-xs px-lg-0">
            <div class="row">
              <div
                class="col-12 col-md-6 col-lg-4 card-deck mx-0 px-space-xs mb-space-sm mb-lg-space-md"
                v-for="(career, index) in currentCareerItems"
              >
                <card-degree-text
                  :title="career.alternateTitle"
                  title-size="small"
                  border-variant="light-4"
                  :icon-description="true"
                  :growth-description="getFormattedGrowth(career.growth)"
                  :salary-description="getFormattedSalary(career.salary)"
                  :show-label="false"
                  class="bg-white w-100 h-100"
                ></card-degree-text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- show more/less links -->
      <div v-if="displayShowAllLess" class="row">
        <div class="col">
          <div class="text-center">
            <button
              @click="handleShowAllLessClick"
              class="btn btn-link fs-medium text-primary show-text text-decoration-underline"
            >
              {{ getShowAllLessText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { CardDegreeText } from "@rds-vue-ui/card-degree-text";

interface CareerItem {
  alternateTitle: string;
  growth: string;
  salary: string;
  title: string;
}

interface Props {
  title: string;
  imageSource: string;
  description: string | null;
  careers: string;
  initialItemsNumber?: number;
}

const props = withDefaults(defineProps<Props>(), {
  initialItemsNumber: 9,
});

const showAllItems = ref(false);

const parsedCareerItems = computed<CareerItem[]>(() => {
  return JSON.parse(props.careers);
});

const currentCareerItems = computed(() => {
  return showAllItems.value
    ? parsedCareerItems.value
    : parsedCareerItems.value.slice(0, props.initialItemsNumber);
});

const getShowAllLessText = computed(() => {
  return showAllItems.value ? "Show less" : "Show more";
});

const displayShowAllLess = computed(() => {
  return parsedCareerItems.value.length > props.initialItemsNumber;
});

const handleShowAllLessClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    getShowAllLessText.value.toLowerCase(),
    "related careers"
  );

  showAllItems.value = !showAllItems.value;
};

const getFormattedGrowth = (growth: string) => {
  return `<strong>Growth: </strong> ${growth} %`;
};

const getFormattedSalary = (salary: string) => {
  return `<strong>Salary: </strong> ${salary}`;
};
</script>

<style scoped>
.career-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 245px;
  padding-left: 0;
  padding-right: 0;
}
.career-image-gradient {
  left: 0px;
  height: 245px;
  position: absolute;
  background: transparent
    linear-gradient(180deg, #ffffff00 0%, #ffffffa0 80%, #ffffff 100%) 0% 0%
    no-repeat;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .career-image {
    height: 400px;
  }
  .career-image-gradient {
    height: 400px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .career-image {
    height: 550px;
  }
  .career-image-gradient {
    height: 550px;
  }
  .title-margin {
    margin-top: -40px;
  }
}
</style>
