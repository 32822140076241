<template>
  <section
    class="px-space-xs px-md-space-md px-lg-space-xl py-space-md py-md-space-lg pt-lg-space-xxl pb-lg-0 section-bg rds-lazy"
    data-background-image="https://live-asuocms.ws.asu.edu/sites/default/files/Topo%20background%402x.jpg"
  >
    <div class="container px-0 pb-lg-space-xxl">
      <!-- title & description -->
      <div class="px-space-xs px-md-0">
        <div class="row gx-0">
          <div class="col">
            <h2 class="mb-space-sm mb-md-space-md mb-lg-space-sm h2-medium">
              {{ title }}
            </h2>
            <div class="mb-space-md mb-md-space-sm mb-lg-space-md">
              <div v-html="description"></div>

              <div v-if="bammProgram.majorMapUrl">
                <nuxt-link
                  :href="bammProgram.majorMapUrl"
                  class="show-text text-primary"
                  @click.native="handleMajorMapClick('view full major map')"
                >
                  View full major map
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
        <!-- horizontal line -->
        <div class="row">
          <div class="col">
            <hr
              class="text-dark-3 hr-underline mb-space-md mb-md-space-sm mb-lg-space-md mt-0"
            />
          </div>
        </div>
      </div>
      <!-- course cards -->
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 mb-space-xs">
        <div
          class="col mb-space-xs mb-lg-space-sm"
          v-for="(course, index) in currentCoursesItems"
          :key="index"
        >
          <card-modal-course
            :title="course.title"
            :description="getDescription(course.description)"
            :event-component="title"
          ></card-modal-course>
        </div>
      </div>

      <!-- show more/less links -->
      <div v-if="displayShowAllLess" class="row">
        <div class="col">
          <div class="text-center">
            <button
              @click="handleShowAllLessClick"
              class="btn btn-link fs-medium text-primary show-text text-decoration-underline"
            >
              {{ getShowAllLessText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { computed, ref } from "vue";
import CardModalCourse from "~/components/degree-page/CardModalCourse.vue";

interface BammProgram {
  majorMapUrl: string;
}

interface Course {
  subject: string;
  catalogNumber: string;
  title: string;
  description: string;
  courseSearchUrl: string;
}

interface Props {
  title: string;
  bammProgram: BammProgram;
  description: string | null;
  courses: string;
  initialItemsNumber?: number;
}

const props = withDefaults(defineProps<Props>(), {
  initialItemsNumber: 8,
});

const allCoursesShown = ref(false);
const formattedCourses = ref<
  Omit<Course, "subject" | "catalogNumber" | "courseSearchUrl">[]
>([]);

const displayShowAllLess = computed(() => {
  return formattedCourses.value.length > props.initialItemsNumber;
});

const getShowAllLessText = computed(() => {
  return allCoursesShown.value ? "Show less" : "Show more";
});

const parsedCoursesItems = computed<Course[]>(() => {
  return JSON.parse(props.courses);
});

const currentCoursesItems = computed(() => {
  return allCoursesShown.value
    ? formattedCourses.value
    : formattedCourses.value.slice(0, props.initialItemsNumber);
});

const handleMajorMapClick = (linkText: string) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    linkText.toLowerCase(),
    "featured courses"
  );
};

const handleShowAllLessClick = () => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    props.title.toLowerCase(),
    getShowAllLessText.value.toLowerCase(),
    "featured courses"
  );
  allCoursesShown.value = !allCoursesShown.value;
};

const getDescription = (courseDescription: string | null) => {
  return courseDescription
    ? courseDescription
    : "No description available at this time.";
};

onMounted(() => {
  if (parsedCoursesItems.value.length > 0) {
    formattedCourses.value = parsedCoursesItems.value.map((obj) => {
      return {
        title: `${obj.subject} ${obj.catalogNumber}: ${obj.title}`,
        description: obj.description,
      };
    });
  }
});
</script>

<style lang="scss" scoped>
.hr-underline {
  height: 0.25px;
}
.section-bg {
  background-size: auto;
  background-position: top;
}
</style>
